<template>
  <v-app>
    <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-alert v-model="success" type="success" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-card>
        <v-card-title class="indigo white--text" style="font-size:30px">
          センサ管理
        </v-card-title>
        <v-row class="pa-4" justify="space-between">
        
          <!-- 画面左部ツリー -->
          <v-col cols="5">

            <v-text-field
              v-model="treeSearch"
              label="Search"
              flat
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              style="margin:8px 38px"
            ></v-text-field>
            <v-treeview
              activatable
              :items="items"
              :active.sync="active"
              :search="treeSearch"
              :filter="filter"
            >
            </v-treeview>
          </v-col>
          <!-- 画面左部ツリー -->
          
          <v-divider vertical></v-divider>
          
          <!-- 画面右部 -->
          <v-col class="d-flex text-center">
            <v-scroll-y-transition mode="out-in">
              <!--未選択-->
              <div
                v-if="!selected"
                class="text-h6 text--lighten-1 font-weight-light"
                style="align-self: top;"
              >
                センサを選択して下さい。
              </div>
              <!--選択中-->
              <v-card
                v-else
                :key="selected.id"
                class="pt-6 mx-auto"
                flat
                width="95%"
              >
                <v-text-field v-if="selected.id === 0" v-model="devid" label="シリアル番号" :rules="devIdRules">
                </v-text-field>
                <v-text-field v-else v-model="devid" label="シリアル番号" disabled>
                </v-text-field>
                
                <v-text-field v-model="devnm" label="ロケーション名">
                </v-text-field>

                <v-select
                v-model="devgroupnm"
                label="センサグループ名"
                :items="sensorgroupList"
                >
                </v-select>

               <div v-if="!isNewDevice">
                  <!-- <v-text-field v-model.number="publishInterval" label="データ送信間隔(秒)" type="number" max="2000" min="0" :rules="numberRules">
                  </v-text-field> -->
                  <v-text-field v-model.number="volume" label="ブザー音量" type="number" max="100.0" min="0.0" :rules="numberRules">
                  </v-text-field>
                  <v-text-field v-model.number="duration" label="ブザー鳴動時間" type="number" max="3000.0" min="0.0" :rules="numberRulesDuration">
                  </v-text-field>
                  
                  <!-- <v-text-field v-model.number="cautionMinCo2" label="注意閾値下限：CO2(ppm)" type="number" max="2000" min="0" :rules="numberRulesCo2">
                  </v-text-field> -->
                  <v-text-field v-model.number="cautionMaxCo2" label="注意閾値上限：CO2(ppm)" type="number" max="2000" min="0" :rules="numberRulesCo2">
                  </v-text-field>
                  <!-- <v-text-field v-model.number="warningMinCo2" label="警告閾値下限：CO2(ppm)" type="number" max="2000" min="0" :rules="numberRulesCo2">
                  </v-text-field> -->
                  <v-text-field v-model.number="warningMaxCo2" label="警告閾値上限：CO2(ppm)" type="number" max="2000" min="0" :rules="numberRulesCo2">
                  </v-text-field>

                  <!-- <v-text-field v-model.number="cautionMinAir" label="注意閾値下限：風量(cm/s)" type="number" max="150" min="-1" :rules="numberRulesAir">
                  </v-text-field>
                  <v-text-field v-model.number="cautionMaxAir" label="注意閾値上限：風量(cm/s)" type="number" max="150" min="-1" :rules="numberRulesAir">
                  </v-text-field>
                  <v-text-field v-model.number="warningMinAir" label="警告閾値下限：風量(cm/s)" type="number" max="150" min="-1" :rules="numberRulesAir">
                  </v-text-field>
                  <v-text-field v-model.number="warningMaxAir" label="警告閾値上限：風量(cm/s)" type="number" max="150" min="-1" :rules="numberRulesAir">
                  </v-text-field> -->

                  <!-- <v-text-field v-model.number="cautionMinTemp" label="注意閾値下限：WBGT" type="number" max="100.0" min="0.0" :rules="numberRules">
                  </v-text-field> -->
                  <v-text-field v-model.number="cautionMaxTemp" label="注意閾値上限：WBGT" type="number" max="100.0" min="0.0" :rules="numberRules">
                  </v-text-field>
                  <!-- <v-text-field v-model.number="warningMinTemp" label="警告閾値下限：WBGT" type="number" max="100.0" min="0.0" :rules="numberRules">
                  </v-text-field> -->
                  <v-text-field v-model.number="warningMaxTemp" label="警告閾値上限：WBGT" type="number" max="100.0" min="0.0" :rules="numberRules">
                  </v-text-field>

                </div>                
                <v-row class="pa-4">
                  <v-spacer></v-spacer>
                  <v-btn small v-on:click.stop="setting()" v-bind:disabled="isSet" color="primary" style="font-size:16px">設定</v-btn>
                  <!--<div v-if="devenabled && !isNewDevice">
                    <v-btn small v-on:click.stop="delDialog = true" v-bind:disabled="isSetDel" color="error" style="font-size:16px">無効</v-btn>
                  </div>
                  <div v-else-if="!devenabled && !isNewDevice">
                    <v-btn small v-on:click.stop="delDialog = true" v-bind:disabled="isSetDel" color="error" style="font-size:16px">有効</v-btn>
                  </div>
                  <div v-else>
                    <v-btn small v-on:click.stop="delDialog = true" v-bind:disabled="isSetDel" color="white" style="font-size:16px">無効</v-btn>
                  </div> -->
                </v-row>
              </v-card>
              
            </v-scroll-y-transition>
          </v-col>
          <!-- 画面右部 -->
        </v-row>
      </v-card>
      
      <v-dialog v-model="delDialog" persistent max-width="300">
        <v-card>
          <v-card-title class="headline blue-grey lighten-3">確認</v-card-title>
          <div v-if="devenabled">
            <v-card-text>
              表示しているセンサを無効にしますか？
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.stop="delDialog = false">
                キャンセル
              </v-btn>
              <v-btn @click.stop="delitem()" color="error">
                無効
              </v-btn>
            </v-card-actions>
          </div>
          <div v-else>
            <v-card-text>
              表示しているセンサを有効にしますか？
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.stop="delDialog = false">
                キャンセル
              </v-btn>
              <v-btn @click.stop="delitem()" color="error">
                有効
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    
    </v-form>
    <v-spacer>
      &nbsp;
    </v-spacer>
    <!-- <v-card>
      <v-card-title class="indigo white--text text-h5">
        センサメンテナンス
      </v-card-title>
      <v-row class="pa-4" justify="space-between">
      
        <v-col cols="6">
          <v-card-text>Update File</v-card-text>
          <v-data-table
            :headers="fileHeaders"
            :items="files"
            class="title"
            v-model="selectedFile"
            item-key="filename"
            show-select
            :single-select="true"
          >
          </v-data-table>
        </v-col>
        
        <v-divider vertical></v-divider>
        
        <v-col cols="6">
          <v-card-text>Device</v-card-text>
          <v-data-table
            :headers="deviceHeaders"
            :items="devices"
            class="title"
            v-model="selectedDevice"
            item-key="devicename"
            show-select
          >
          </v-data-table>
        </v-col>        
      </v-row>
    </v-card>
    <v-row class="pa-4">
      <v-spacer></v-spacer>
      <v-btn small v-on:click="updating()" v-bind:disabled="isUpdate" :loading="updateLoading" color="primary">UPDATE</v-btn>
    </v-row> -->
    </v-container>
  </v-app>
</template>
<script>

  export default {
    data: () => ({
      //表示・編集用センサ情報
      id:null,
      devid:null,
      devnm:null,
      devgroupid:null,
      devgroupnm:null,
      devenabled:true,
      publishInterval:60,
      volume:0,
      duration:3000,
      cautionMinCo2:0,
      cautionMaxCo2:800,
      warningMinCo2:0,
      warningMaxCo2:1500,
      cautionMinAir:-1,
      cautionMaxAir:150,
      warningMinAir:-1,
      warningMaxAir:150,
      cautionMinTemp:0,
      cautionMaxTemp:25,
      warningMinTemp:0,
      warningMaxTemp:28,
      cautionMinHum:0,
      cautionMaxHum:0,
      warningMinHum:0,
      warningMaxHum:0,
      timestamp:null,
      isNewDevice:false,
      
      isSet:true,
      isSetDel:true,
      isUpdate:true,
      
      active: [],
      items: [],
      sensors: [],
      sensorgroup: [],
      sensorgroupList: [],
      fileHeaders:[
          { text:'Name', sortable: false, value:'filename' },
          { text:'Size[B]', sortable: false, value:'filesize' },
      ],
      files:[],
      selectedFile:[],
      deviceHeaders:[
          { text:'ID', sortable: false, value:'deviceid' },
          { text:'Location', sortable: false, value:'devicename' },
          { text:'Version', sortable: false, value:'version' },
      ],
      devices:[],
      selectedDevice:[],      
      apigwurl: process.env.VUE_APP_APIURL,
      dialogText:null,
      delDialog:false,
      
      success:false,
      error:false,
      updateLoading: false,
      
      valid: true,

      treeSearch: null,
    }),
    computed: {
      selected () {
        if (!this.active.length) return undefined

        const activeid = this.active[0]
        var sensor = this.items.find(item => item.id === activeid)
        
        this.setdata(sensor);
        
        return sensor
      },
      devIdRules() {
        return [
          v => !!v || '必須項目です。'
        ]
      },
      numberRules() {
        return [
          v => v <= 100 || '0～100の範囲で入力してください。',
          v => v >= 0 || '0～100の範囲で入力してください。'
        ]
      },
      numberRulesDuration() {
        return [
          v => v <= 3000 || '0～3000の範囲で入力してください。',
          v => v >= 0 || '0～3000の範囲で入力してください。'
        ]
      },
      numberRulesCo2() {
        return [
          v => v <= 2000 || '0～2000の範囲で入力してください。',
          v => v >= 0 || '0～2000の範囲で入力してください。'
        ]
      },
      numberRulesAir() {
        return [
          v => v <= 150 || '-1～150の範囲で入力してください。',
          v => v >= -1 || '-1～150の範囲で入力してください。'
        ]
      },
      filter(){
        return (item, search, textKey) => this.filterNodes(item, search, textKey)
      }
    },
    methods: {
      filterNodes: function(item, search, textKey){
        this.active = [];
        if(item[textKey].indexOf(search) > -1){
          return true
        } else {
          if(item['dev_name'] && item['dev_name'].indexOf(search) > -1){
            return true
          } else {
            return false
          }
        }
      },
      setting: function(){
        if(this.id === 0){
          for(var x = 0; x < this.items.length; x++){
            if( this.devid === this.items[x].dev_id){
              this.dialogText = '同じシリアル番号は登録できません'
              this.success = false;
              this.error = true;
              this.returnTop('smooth');
              return
            }
          }
        }
        this.updateSensor();
      },
      delitem: function(){
        this.deleteSensor();
        this.delDialog = false;
        if(this.devenabled){
          this.dialogText = 'センサを無効にしました。';
        }
        else{
          this.dialogText = 'センサを有効にしました。';
        }
        this.success = true;
      },
      //センサ情報設定
      setdata: function(sensor){
        this.id = sensor.id
        this.devid = sensor.dev_id
        this.devnm = sensor.dev_name
        this.devgroupid = sensor.sensorgroupid
        this.publishInterval = sensor.publishInterval
        this.volume = sensor.volume
        this.duration = sensor.duration
        this.cautionMinCo2 = sensor.co2_cautionThresholdLow
        this.cautionMaxCo2 = sensor.co2_cautionThresholdHigh
        this.warningMinCo2 = sensor.co2_warningThresholdLow
        this.warningMaxCo2 = sensor.co2_warningThresholdHigh
        this.cautionMinAir = sensor.airflow_cautionThresholdLow
        this.cautionMaxAir = sensor.airflow_cautionThresholdHigh
        this.warningMinAir = sensor.airflow_warningThresholdLow
        this.warningMaxAir = sensor.airflow_warningThresholdHigh
        this.cautionMinTemp = sensor.temperature_cautionThresholdLow
        this.cautionMaxTemp = sensor.temperature_cautionThresholdHigh
        this.warningMinTemp = sensor.temperature_warningThresholdLow
        this.warningMaxTemp = sensor.temperature_warningThresholdHigh
        this.cautionMinHum = sensor.humidity_cautionThresholdLow
        this.cautionMaxHum = sensor.humidity_cautionThresholdHigh
        this.warningMinHum = sensor.humidity_warningThresholdLow
        this.warningMaxHum = sensor.humidity_warningThresholdHigh
        this.devenabled = sensor.enabled
        if(sensor.dev_id == null){
          this.isNewDevice = true;
        }
        else{
          this.isNewDevice = false;
        }
        this.devgroupnm = null
        var newItems = new Array()
        // 未選択
        var noItem = {
          "sensorgroup_name":"---",
          "sensorgroupid":null
        }
        if (this.sensorgroup.length>0){
          this.$set(this.sensorgroup, this.sensorgroup.length, noItem);
        }
        this.$set(newItems, newItems.length, "---");
        for(var i=0; i<this.sensorgroup.length; i++){
          this.$set(newItems, i, this.sensorgroup[i].sensorgroup_name);
          if(this.sensorgroup[i].sensorgroupid == this.devgroupid){
            this.devgroupnm = this.sensorgroup[i].sensorgroup_name;
          }
        }
        newItems.sort()
        this.sensorgroupList = newItems
      },
      //センサ情報登録
      updateSensor:function(){
        if(this.$refs.form.validate()){
          //lambda関数URL
          var newDevId = "evs_" + this.devid;
          var apiurl = this.apigwurl + "/sensors/" + newDevId
          var param = {}
          for(var i=0; i<this.sensorgroup.length; i++){
            if(this.sensorgroup[i].sensorgroup_name == this.devgroupnm){
              this.devgroupid = this.sensorgroup[i].sensorgroupid;
              break;
            }
          }
          if(this.isNewDevice){
            //New Device
            console.log('New Device')
            if(this.devnm == null){
              this.devnm = this.devid
            }
            param = {
                  "dev_id": newDevId,
                  "dev_name": this.devnm,
                  "sensorgroupid":this.devgroupid,
                  "publishInterval":60,
                  "volume":0,
                  "duration":3000,
                  "co2_cautionThresholdLow": 0,
                  "co2_cautionThresholdHigh": 800,
                  "co2_warningThresholdLow": 0,
                  "co2_warningThresholdHigh": 1500,
                  "airflow_cautionThresholdLow": -1,
                  "airflow_cautionThresholdHigh": 150,
                  "airflow_warningThresholdLow": -1,
                  "airflow_warningThresholdHigh": 150,
                  "temperature_cautionThresholdLow": 0,
                  "temperature_cautionThresholdHigh": 25,
                  "temperature_warningThresholdLow": 0,
                  "temperature_warningThresholdHigh": 28,
                  "humidity_cautionThresholdLow": 0,
                  "humidity_cautionThresholdHigh": 0,
                  "humidity_warningThresholdLow": 0,
                  "humidity_warningThresholdHigh": 0 
            }
            this.active = []; //新規登録時にエラーメッセージが意図しないタイミングで表示される事象の対応
            this.$axios.post(apiurl, param).then(res => {
              console.log(res)
              this.getSensor()
              this.dialogText = 'センサ情報の追加が完了しました。';
              this.success = true;
              this.error = false;
              this.returnTop('auto');
            })
            .catch((err) => {
              console.log(err);
              this.dialogText = 'センサ情報の追加に失敗しました。';
              this.success = false;
              this.error = true;
              this.returnTop('auto');
            });
            this.isNewDevice = false;
          }
          else{
            //Update Device
            for(i=0; i<this.sensorgroup.length; i++){
              if(this.sensorgroup[i].sensorgroup_name == this.devgroupnm){
                this.devgroupid = this.sensorgroup[i].sensorgroupid;
                break;
              }
            }
            param = {
                  "dev_name": this.devnm,
                  "sensorgroupid":this.devgroupid,
                  "publishInterval":this.publishInterval,
                  "volume":this.volume,
                  "duration":this.duration,
                  "co2_cautionThresholdLow": this.cautionMinCo2,
                  "co2_cautionThresholdHigh": this.cautionMaxCo2,
                  "co2_warningThresholdLow": this.warningMinCo2,
                  "co2_warningThresholdHigh": this.warningMaxCo2,
                  "airflow_cautionThresholdLow": this.cautionMinAir,
                  "airflow_cautionThresholdHigh": this.cautionMaxAir,
                  "airflow_warningThresholdLow": this.warningMinAir,
                  "airflow_warningThresholdHigh": this.warningMaxAir,
                  "temperature_cautionThresholdLow": this.cautionMinTemp,
                  "temperature_cautionThresholdHigh": this.cautionMaxTemp,
                  "temperature_warningThresholdLow": this.warningMinTemp,
                  "temperature_warningThresholdHigh": this.warningMaxTemp,
                  "humidity_cautionThresholdLow": this.cautionMinHum,
                  "humidity_cautionThresholdHigh": this.cautionMaxHum,
                  "humidity_warningThresholdLow": this.warningMinHum,
                  "humidity_warningThresholdHigh": this.warningMaxHum
            }
            console.log('Update Device')
            
            this.$axios.put(apiurl, param).then(res => {
              console.log(res)
              this.getSensor()
              this.dialogText = 'センサ情報の更新が完了しました。';
              this.success = true;
              this.error = false;
              this.returnTop('auto');
            })
            .catch((err) => {
              console.log(err);
              this.dialogText = 'センサ情報の更新に失敗しました。';
              this.success = false;
              this.error = true;
              this.returnTop('auto');
            });
          }
        } else {
          this.dialogText = '不正な値が入力されています。入力内容を確認してください。'
          this.success = false;
          this.error = true;
          this.returnTop('smooth');
          return
        }
      },
      //センサ情報無効
      deleteSensor: function(){
        //lambda関数URL
        var apiurl = this.apigwurl + "/sensors/evs_" + this.devid + "?enabled=" + !this.devenabled
        this.$axios.delete(apiurl, null).then(res => {
          console.log(res)
          this.getSensor()
          this.returnTop('auto');
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサの無効化に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      //センサ情報取得
      getSensor:function(){
        var apiurl = this.apigwurl + "/sensors" + "?sensorgroupid=Administrator All Device"
        this.$axios.get(apiurl, null).then(res => {
          this.items = new Array();
          var newItems = new Array();
          this.sensors = new Array();
          
          this.sensors = res.data;
          //表示用に加工
          var cnt = 1;
          if(res.data != ""){
            this.sensors.forEach(function( value ){
              if(value['dev_id'].indexOf('evs_') != -1){
                value['dev_id'] = value['dev_id'].replace('evs_','')
              }
              //if(value['dev_name'] == null){
              //  value['name'] = '(No device name)';
              //}
              //else{
              //  value['name'] = value['dev_name'];
              //}
              value['name'] = value['dev_id'];
              value['id'] = cnt;
              newItems.push(value);
              cnt = cnt + 1;
            });
          }          
          this.items = newItems;
          this.items.sort(function(a, b){
            if(a.dev_name < b.dev_name){
              return -1;
            } else {
              return 1;
            }
          });
          this.getDeviceslist(this.items);
          this.getFilelist();
          // this.getShadowlist(res.data);
          
          this.items.unshift({
            id:0,
            name:'新規作成',
            devid:null,
            devnm:null,
            warminco2:0,
            warmaxco2:800,
            danminco2:0,
            danmaxco2:1500,
            warminair:-1,
            warmaxair:150,
            danminair:-1,
            danmaxair:150,
            warmintemp:0,
            warmaxtemp:25,
            danmintemp:0,
            danmaxtemp:28,
            warminhum:0,
            warmaxhum:0,
            danminhum:0,
            danmaxhum:0
          });
          //選択状態初期化
          this.active = [];
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサ情報の取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });

        apiurl = this.apigwurl + "/sensor_group"
        this.$axios.get(apiurl, null).then(res => {
          this.sensorgroup = new Array();
          
          this.sensorgroup = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサグループの取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      returnTop(action) {
        window.scrollTo({
          top: 0,
          behavior: action
        })
      },
      getDeviceslist: function(deviceList){
        this.devices = [];
        for(var dev in deviceList){
          var item = {
            devicename : deviceList[dev].dev_name,
            deviceid: deviceList[dev].dev_id,
            version : "0.0.0"
          };
          this.devices.push(item);
        }
      },
      getFilelist: function(){
        // Storage.list('binary')
        //   .then(result => {
        //     console.log(JSON.parse(JSON.stringify(result)))
        //     var s3Data = JSON.parse(JSON.stringify(result))
        //     this.files = []
        //     console.log(s3Data)
        //     for (var i = 0; i < s3Data.length; i++ ){
        //       // this.$set( this.otaItems, i, this.s3Data[i] );
        //     }
        //   })
        //   .catch((err) => { console.log(err);})
        var fileList = [
          {
            filename:"test.bin",
            filesize:1200000
          },
          {
            filename:"evs-sensor-node-1-1-12.bin",
            filesize:1200000
          }
        ]
        this.files = fileList
      },
      // getShadowlist: function(deviceList){
      //   console.log(deviceList)
      //   this.$axios.get(this.apigwurl + "/sensors" + "?sensorgroupid=001", "").then(res => {
      //     console.log(res)
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.dialogText = 'センサ情報の取得に失敗しました。';
      //     this.success = false;
      //     this.error = true;
      //     this.returnTop('auto');
      //   });
      // },
      updating: function(){
        this.updateLoading = true;
        var device = []
        for(var dev in this.selectedDevice){
          var newDevId = "evs_" + this.selectedDevice[dev].deviceid;
          device.push(newDevId);
        }
        var param = {
          "deviceid": device,
          "filename": this.selectedFile[0]['filename']
        }
        this.$axios.post(this.apigwurl + "/sensor_update/ota", param).then(res => {
          console.log(res)
          this.dialogText = 'センサアップデートの実行が開始されました';
          this.success = true;
          this.error = false;
          this.returnTop('auto');
          this.updateLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサアップデートの実行が開始されませんでした';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
          this.updateLoading = false;
        });
      }
    },
    mounted() {
      this.getSensor();
    },
    watch: {
      //設定ボタン
      devid: function(newValue){
        if(newValue){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      // devnm: function(newValue){
      //   if(newValue && this.devid){
      //     this.isSet = false;
      //   } else {
      //     this.isSet = true;
      //   }
      // },
      //削除ボタン(enabled=false)
      id: function(newValue){
        if(newValue){
          if(newValue === 0){
            this.isSetDel = true;
          } else {
            this.isSetDel = false;
          }
        } else {
          this.isSetDel = true;
        }
      },
      //UPDATE
      selectedDevice: function(){
        if(this.selectedFile=="" || this.selectedDevice.length==0){
          this.isUpdate = true;
          }else{
            this.isUpdate = false;
          }
      },
      selectedFile: function(){
        if(this.selectedFile=="" || this.selectedDevice.length==0){
          this.isUpdate = true;
          }else{
            this.isUpdate = false;
          }
      }
    }
  }
 </script>